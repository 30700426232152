import utils from "./utils";
import config from "../config";

// gets Bitbucket information including workspaces, projects, users, admins, project reviewers and templates
const getConfig = (accessToken) => {
  const uri = `${config.endpoints.backend}/config`;
  return utils.fetchWrapper(uri, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  });
};

// creates a Bitbucket repos
const createRepository = (requestBody, accessToken) => {
  const uri = `${config.endpoints.backend}/workspaces/${requestBody.workspace}/repositories`;
  return utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(requestBody)
  });
};

// gets metadata for the container that is hosting the repo creator backend
const getMetadata = (accessToken) => {
  const uri = `${config.endpoints.backend}/metadata`;
  return utils.fetchWrapper(uri, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const putProjectReviewers = (...params) => {
  const [workspaceId, projectId, requestBody, accessToken] = params;
  const uri = `${config.endpoints.backend}/workspaces/${workspaceId}/projects/${projectId}/reviewers`;
  return utils.fetchWrapper(uri, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(requestBody)
  });
};

export default {
  getConfig,
  createRepository,
  getMetadata,
  putProjectReviewers
};
