import React, { useState } from "react";
import { AppBar, Box, Toolbar, Typography, Tabs, Tab } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

// The value determines which tab should be selected based off of the user's current path location
const getPathLocationValue = (path, adminAccess) => {
  let value = "";
  switch (path) {
    case "/home":
      value = 0;
      break;
    case "/reviewers":
      value = 2;
      break;
    case "/user-guide":
      value = adminAccess ? 3 : 2;
      break;
    default:
      value = 0;
  }
  return value;
};

/* The Header component will display navigation tabs based on if the user is an admin of at least one Bitbucket workspace */
const Header = ({ adminAccess }) => {
  let location = useLocation();
  const [value, setValue] = useState(() =>
    getPathLocationValue(location.pathname, adminAccess)
  );

  // this is invoked when a user clicks on a header navigation tab
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/home" style={{ textDecoration: "none", color: "white" }}>
              Repository Creator
            </Link>
          </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            indicatorColor="secondary"
          >
            <Tab
              label="Create Repository"
              value={0}
              component={Link}
              to="/home"
            ></Tab>
            {adminAccess && (
              <Tab
                label="Set Reviewers"
                value={2}
                component={Link}
                to={{
                  pathname: "/reviewers",
                  state: { adminAccess: adminAccess }
                }}
              ></Tab>
            )}
            <Tab
              label="User Guide"
              value={adminAccess ? 3 : 2}
              component={Link}
              to={{
                pathname: "/user-guide",
                state: { adminAccess: adminAccess }
              }}
            ></Tab>
          </Tabs>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
