// wrapper for the fetch api
export const fetchWrapper = async (url, init) => {
  const response = await fetch(url, init);
  const json = await response.json();
  return response.ok ? json : Promise.reject(json);
};

// gets all of the Bitbucket workspaces that the Bitbucket user is in
export const getWorkspaces = (config, userName) => {
  return config.workspaces
    .filter((workspace) =>
      workspace.members.find((member) => member.name === userName)
    )
    .map((workspace) => workspace.id);
};

// gets the teams that belong to the Bitbucket workspace
export const getTeams = (config, workspaceName) => {
  return config.workspaces
    .filter((workspace) => workspace.id === workspaceName)
    .map((workspace) => workspace.teams)
    .flat()
    .map((team) => team.name);
};

// gets the team id for the team selected
export const getTeamId = (config, workspaceName, teamName) => {
  return config.workspaces
    .filter((workspace) => workspace.id === workspaceName)
    .map((workspace) => workspace.teams)
    .flat()
    .find((team) => team.name === teamName).id;
};

// gets all of the Bitbucket projects names for a Bitbucket workspace
export const getProjectNames = (config, workspaceName) => {
  return config.workspaces
    .filter((workspace) => workspace.id === workspaceName)
    .map((workspace) => workspace.projects)
    .flat()
    .map((project) => project.name);
};

// gets the Bitbucket project key
export const getProjectKey = (config, workspaceName, projectName) => {
  return config.workspaces
    .filter((workspace) => workspace.id === workspaceName)
    .map((workspace) => workspace.projects)
    .flat()
    .find((project) => project.name === projectName).key;
};

// gets the types of templates
export const getTemplateTypes = (config) => {
  return [...new Set(config.templates.map((template) => template.type))];
};

// gets the templates available based off of the template type
export const getTemplates = (config, templateType) => {
  return config.templates
    .filter((template) => template.type === templateType)
    .map((template) => template.name);
};

// get the template id for the given template
export const getTemplateId = (config, templateName) => {
  return config.templates
    .filter((template) => template.name === templateName)
    .map((template) => template.id)
    .toString();
};

// get the project id for the Bitbucket project
export const getProjectId = (config, workspaceName, projectName) => {
  return config.workspaces
    .filter((workspace) => workspace.id === workspaceName)
    .map((workspace) => workspace.projects)
    .flat()
    .filter((project) => project.name === projectName)
    .map((project) => project.id)
    .toString();
};

// generates the name that is used to display the Bitbucket repo name
export const generateName = (projectNumber, customerName, solutionName) => {
  if (projectNumber)
    return `${projectNumber} ${customerName} - ${solutionName}`;
  else if (customerName) return `${customerName} - ${solutionName}`;
  else return `${solutionName}`;
};

// replaces spaces with dashes and makes the letters lowercase
export const formatString = (string) => {
  return string.replace(/  +/g, " ").trim().replace(/\s+/g, "-").toLowerCase();
};

// formats the text input value
export const formatTextInputValue = (value) => {
  return value.toLowerCase().replaceAll(" ", "-");
};

// formats the repository name
export const formatRepositoryName = (repositoryName) => {
  return repositoryName.replace(/  +/g, " ").trim().toLowerCase();
};

// generates the Bitbucket slug of the Bitbucket repo
export const slugify = (repositoryName) => {
  return repositoryName
    .replace(/-/g, " ")
    .toLowerCase()
    .replace(/  +/g, " ")
    .trim()
    .replace(/\s+/g, "-");
};

// gets the project reviewers of the Bitbucket project
export const getProjectReviewers = (config, workspaceName, projectId) => {
  const projectReviewers = config.workspaces
    .filter((workspace) => workspace.id === workspaceName)
    .map((workspace) => workspace.projects)
    .flat()
    .find((project) => project.id === projectId).reviewers;
  return projectReviewers.length > 0 ? projectReviewers : [];
};

// gets the Bitbucket members of the Bitbucket workspace and sorts the names alphabetically
export const getWorkspaceMembers = (config, workspaceName) => {
  return config.workspaces
    .find((workspace) => workspace.id === workspaceName)
    .members.sort((a, b) => (a.name > b.name ? 1 : -1));
};

// searches the Bitbucket workspace admin list to see if the Bitbucket user is an admin of at least one workspace
export const getAdminAccess = (config, userName) => {
  return config.workspaces
    .map((workspace) => workspace.admins)
    .flat()
    .find((admin) => admin.name === userName)
    ? true
    : false;
};

// gets the workspaces the Bitbucket user is an admin of
export const getAdminWorkspaces = (config, userName) => {
  return config.workspaces
    .filter((workspace) =>
      workspace.admins.find((admin) => admin.name === userName)
    )
    .map((workspace) => workspace.id);
};

// transforms the string into camelCase
export const camelCase = (string) => {
  let hasMultipleWords = string.split(" ")[1];

  if (hasMultipleWords) {
    const words = string.toLowerCase().split(" ");
    let camelCaseKey = string.split(" ")[0].toLocaleLowerCase();

    let index = 1;
    while (index < words.length) {
      camelCaseKey = camelCaseKey +=
        words[index].charAt(0).toUpperCase() + words[index].slice(1);
      index++;
    }
    return camelCaseKey;
  } else {
    return string.toLowerCase();
  }
};

export default {
  fetchWrapper,
  getWorkspaces,
  getTeams,
  getTeamId,
  getProjectNames,
  getTemplateTypes,
  getTemplates,
  getTemplateId,
  getProjectId,
  generateName,
  slugify,
  getProjectReviewers,
  getWorkspaceMembers,
  getAdminAccess,
  getAdminWorkspaces,
  getProjectKey,
  formatString,
  camelCase,
  formatRepositoryName,
  formatTextInputValue
};
